var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.name === 'Simulations')?_c('div',{attrs:{"id":"SimulationsList"}},[_c('actions',{attrs:{"view":_vm.view,"isSimulationContext":true,"queryConfig":_vm.queryConfig},on:{"changeView":function($event){return _vm.changeView($event)},"applyFilter":function($event){return _vm.applyFilter($event)},"reloadData":function($event){return _vm.applyFilter($event)},"download":function($event){return _vm.download($event)},"search":_vm.simulationSearch}}),(_vm.loading)?_c('page-loader',{attrs:{"hasActions":true}}):_vm._e(),_c('div',{staticClass:"giga-simulations_content"},[(!_vm.loading)?_c('b-tabs',{on:{"activate-tab":function($event){return _vm.onTabChange($event)}}},_vm._l((_vm.tabs),function(tab,index){return _c('b-tab',{key:tab.key,attrs:{"title":tab.title,"active":_vm.activeTab === index}},[(_vm.view == 'grid' && !_vm.loading)?_c('div',[_c('simulation-grid',{staticStyle:{"margin-top":"20px"},attrs:{"simulations":_vm.getSimulations.content}})],1):_vm._e(),(
              _vm.view == 'list' && !_vm.loading && _vm.getSimulations.content.length != 0
            )?_c('table',{staticClass:"giga-table",attrs:{"aria-describedby":_vm.$t('all_simulations')}},[_c('thead',[_c('tr',[_c('th',{staticClass:"sort",class:_vm.sortBy == 'name' ? _vm.sortDir : '',attrs:{"scope":"col"},on:{"click":function($event){return _vm.sortTable('name')}}},[_vm._v(" "+_vm._s(_vm.$t("simulation_name"))+" ")]),_c('th',{staticClass:"sort",class:_vm.sortBy == 'estimated_end_date' ? _vm.sortDir : '',attrs:{"scope":"col"},on:{"click":function($event){return _vm.sortTable('estimated_end_date')}}},[_vm._v(" "+_vm._s(_vm.$t("estimated_end_date"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("Vol. théorique Classique"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("Vol. théorique Précaire"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("Valorisation économique"))+" ")]),_c('th',{staticClass:"sort simulations-status",class:_vm.sortBy == 'status' ? _vm.sortDir : '',attrs:{"scope":"col"},on:{"click":function($event){return _vm.sortTable('status')}}},[_vm._v(" "+_vm._s(_vm.$t("status"))+" ")])])]),_c('tbody',[_vm._l((_vm.getSimulations.content),function(simulation){return [_c('tr',{key:simulation.id,staticClass:"cursor-pointer",attrs:{"id":("sim-" + (simulation.name))},on:{"click":function($event){return _vm.onRowSelected(simulation.id)}}},[_c('td',{staticClass:"simulation-name-column",class:{ 'is-draft': simulation.isDraft }},[_vm._v(" "+_vm._s(simulation.name)+" "),((simulation.cofracState === 'BOTH' || simulation.cofracState === 'ONLY_COFRAC')
                                                      &&!simulation.isDraft && simulation.status !== 'CANCEL' &&
                                                      (simulation.status === 'NEW' || simulation.status === 'CARRIED_OUT' || simulation.status === 'TO_BE_TRANSFORMED'))?_c('span',{staticClass:"tooltip-container"},[_c('svg',{staticClass:"warning-icon",attrs:{"viewBox":"0 0 15 15","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"triangle",attrs:{"d":"M7.5 1.2\n                             C7 1.2 6.7 1.5 6.5 1.8\n                             L0.8 11.2\n                             A1.5 1.5 0 0 0 2.2 13\n                             H12.8\n                             A1.5 1.5 0 0 0 14.2 11.2\n                             L8.5 1.8\n                             C8.3 1.5 8 1.2 7.5 1.2Z"}}),_c('path',{staticClass:"exclamation-mark",attrs:{"d":"M7.5 3.5C7.75 3.5 8 3.75 8 4L7.8 8C7.8 8.25 7.6 8.5 7.5 8.5C7.4 8.5 7.2 8.25 7.2 8L7 4C7 3.75 7.25 3.5 7.5 3.5Z"}}),_c('circle',{staticClass:"exclamation-mark",attrs:{"cx":"7.5","cy":"10.5","r":"0.8"}})]),_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(_vm.$t("quality_control_required")))])]):_vm._e(),(
                        simulation.isDraft && simulation.status !== 'CANCEL'
                      )?_c('span',{staticClass:"draft-badge-list"},[_vm._v("Brouillon")]):_vm._e(),(simulation.status === 'CANCEL')?_c('span',{staticClass:"perdu-badge-list"},[_vm._v("Perdu")]):_vm._e()]),_c('td',{class:{ 'is-draft': simulation.isDraft }},[_vm._v(" "+_vm._s(simulation.deliveryDate > 0 ? _vm.moment(simulation.deliveryDate, "x") .locale("fr") .format("DD MMMM YYYY") : _vm.moment() .locale("fr") .format("DD MMMM YYYY"))+" ")]),_c('td',[_c('p',{class:{ 'is-draft': simulation.isDraft }},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(simulation.expectedThClassicVolume))+" kWh cumac ")])]),_c('td',{class:{ 'is-draft': simulation.isDraft }},[_c('p',{class:{ 'is-draft': simulation.isDraft }},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(simulation.expectedThPrecarityVolume))+" kWh cumac ")])]),_c('td',{class:{ 'is-draft': simulation.isDraft }},[_c('p',{class:{ 'is-draft': simulation.isDraft }},[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(simulation.economicValorisation))+" ")])]),_c('td',{staticClass:"simulations-status"},[_c('b-badge',{staticClass:"giga-badge",class:[
                        { inProgress: simulation.status == 'NEW' },
                        { delivered: simulation.status == 'CARRIED_OUT' },
                        { perdu: simulation.status == 'CANCEL' },
                        { draft: simulation.isDraft }
                      ]},[_vm._v(" "+_vm._s(simulation.isDraft ? "Brouillon" : _vm.$t("simulation_status." + simulation.status))+" ")])],1)])]})],2)]):_vm._e(),(!_vm.getSimulations.content.length)?_c('div',[(
                !_vm.search.length && !_vm.customFilter.rules.length && _vm.activeTab === 0
              )?_c('empty-state',{attrs:{"module":"simulations","title":"Commencer à estimer votre projet.","description":"Vous n'avez aucune simulation.","addSimulation":true}}):_c('empty-state',{attrs:{"module":"simulations","title":"Aucun élément trouvé pour l’instant!","description":"Cette liste ne contient aucun élément.","addSimulation":false}})],1):_vm._e()])}),1):_vm._e(),_c('pagination',{attrs:{"length":_vm.length,"rows":_vm.rows,"perPage":_vm.perPage,"currentPage":_vm.currentPage},on:{"changePerPage":function($event){return _vm.changePerPage($event)},"changeCurrentPage":function($event){return _vm.changeCurrentPage($event)}}}),_c('confirm-modal',{attrs:{"id":"confirm-validation","content":_vm.$t('validate-simulation-message'),"modal_title":_vm.$t('Confirm-validation'),"loader":false,"fontSize":"h3","backdrop-close":"false"},on:{"yesClick":function($event){return _vm.validate(false, 'confirm-validation')},"close":function($event){return _vm.closeConfirmModal('confirm-validation')}}}),_c('confirm-modal',{attrs:{"id":"confirm-lose","content":_vm.$t('cancel-simulation-message'),"modal_title":_vm.$t('Confirm-lose'),"loader":false,"fontSize":"h3","backdrop-close":"false"},on:{"yesClick":function($event){return _vm.validate(true, 'confirm-lose')},"close":function($event){return _vm.closeConfirmModal('confirm-lose')}}})],1)],1):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }